import { ControlsProvider } from '@contexts/controls_context'
import { CookiesProvider } from 'react-cookie'
import DataFecther from '@components/data_fetcher'
import { ErrorProvider } from '@contexts/error_context'
import React from 'react'
import { ThemeProvider } from '@contexts/theme_context'
import { configureAxios } from '@utils/axios'

export default function App() {
  configureAxios()

  return (
    <ThemeProvider>
      <ErrorProvider>
        <CookiesProvider>
          <ControlsProvider>
            <DataFecther />
          </ControlsProvider>
        </CookiesProvider>
      </ErrorProvider>
    </ThemeProvider>
  )
}
