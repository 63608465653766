import {
  CircularProgress,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core'
import React, { FunctionComponent } from 'react'

import { COLORS } from '@utils/colors'
import Logo from '@assets/favicon.svg'

const theme = createTheme({
  palette: {
    secondary: {
      main: COLORS.secondary,
    },
  },
})

const Loading: FunctionComponent = () => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <img src={Logo} alt="SAIL.cloud Logo" />
      <MuiThemeProvider theme={theme}>
        <CircularProgress color="secondary" />
      </MuiThemeProvider>
    </div>
  )
}

export default Loading
