import React, { FunctionComponent, ReactNode, useState } from 'react'

import Error from '@components/error'

const ErrorContext = React.createContext<IErrorProvider>({} as IErrorProvider)

export interface IErrorProvider {
  setError: (error: string) => void
}

interface ControlsProviderProps {
  children: ReactNode
}

export const ErrorProvider: FunctionComponent<ControlsProviderProps> = (
  props,
) => {
  const [error, setError] = useState<string>()

  if (error === undefined) {
    return (
      <ErrorContext.Provider value={{ setError: setError }}>
        {props.children}
      </ErrorContext.Provider>
    )
  } else {
    return <Error errorMessage={error} />
  }
}

export default ErrorContext
