import Map, { IconDataObject } from '@components/map'
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import ViewState, { ViewStateObject } from '@view_models/view_state'
import { eventSlug, isInEventMode } from '@utils/event'

import Clock from '@components/clock'
import ControlsContext from '@contexts/controls_context'
import { DEFAULT_EVENT_INFO } from '@utils/consts'
import { DateTime } from 'luxon'
import { EXPLORE_DATA } from '~/mock_data/explore'
import EventInfo from '@view_models/event_info'
import Loading from '@components/loading'
import Modal from '@components/modal'
import ModalMapBackground from '@components/modal_map_background'
import OptionDrawer from '@components/option_drawer'
import TopBar from '@components/top_bar'
import getBoats from '@api/get_boats'
import getEventBoats from '@api/get_event_boats'
import getEventInfos from '@api/get_event_infos'

const ONE_MINUTE = 60000

const getViewStateFromBoats = (boats: []): ViewStateObject => {
  const max_longitude = Math.max.apply(
    Math,
    boats.map((boat: any) => {
      return boat.coordinate[0]
    }),
  )
  const min_longitude = Math.min.apply(
    Math,
    boats.map((boat: any) => {
      return boat.coordinate[0]
    }),
  )
  const max_latitude = Math.max.apply(
    Math,
    boats.map((boat: any) => {
      return boat.coordinate[1]
    }),
  )
  const min_latitude = Math.min.apply(
    Math,
    boats.map((boat: any) => {
      return boat.coordinate[1]
    }),
  )

  return ViewState.fromRectangle([
    [max_longitude, max_latitude],
    [min_longitude, min_latitude],
  ] as [[number, number], [number, number]])
}

const DataFecther: FunctionComponent = () => {
  const controls = useContext(ControlsContext)
  const [boats, setBoats] = useState<IconDataObject[] | null>(null)
  const [refreshBoatsDataIsPending, setRefreshBoatsDataIsPending] =
    useState<boolean>(false)
  const [fleetViewState, setFleetViewState] = useState<ViewStateObject | null>(
    null,
  )
  const [error, setError] = useState<string | null>(null)
  const [lastRefresh, _setLastRefresh] = useState<DateTime>(DateTime.now())
  const eventHasLoaded = useMemo(
    () =>
      (isInEventMode && controls.eventInfo !== DEFAULT_EVENT_INFO) ||
      !isInEventMode,
    [controls.eventInfo],
  )

  const setLastRefresh = (boatsData: IconDataObject[]) => {
    if (boatsData != undefined && boatsData?.length > 0) {
      const lastPositionTimestamp = Math.max(
        ...boatsData.map((boat) =>
          DateTime.fromISO(boat.timestamp_utc).toSeconds(),
        ),
      )
      _setLastRefresh(DateTime.fromSeconds(lastPositionTimestamp))
    } else {
      _setLastRefresh(DateTime.now())
    }
  }

  const setBoatsData = (boatsData: []) => {
    if (boatsData.length > 0) {
      setBoats(boatsData)
      setFleetViewState(getViewStateFromBoats(boatsData))
      setLastRefresh(boatsData)
    } else {
      setError('Aucunes données disponibles de cet événement pour le moment.')
    }
  }

  const fetchData = () => {
    if (refreshBoatsDataIsPending) {
      return
    }

    setRefreshBoatsDataIsPending(true)
    if (eventSlug) {
      getEventBoats(eventSlug)
        .then(setBoatsData)
        .catch(setError)
        .finally(() => setRefreshBoatsDataIsPending(false))
    } else {
      getBoats()
        .then(setBoatsData)
        .catch(setError)
        .finally(() => setRefreshBoatsDataIsPending(false))
    }
  }

  useEffect(() => {
    if (eventSlug) {
      getEventInfos(eventSlug)
        .then((eventInfo: EventInfo) => {
          controls.setEventInfo(eventInfo)
        })
        .catch(setError)
    }
  }, [])

  useEffect(() => {
    if (eventHasLoaded) {
      fetchData()
      // HOTFIX 2022-06-16 : Causes database perf
      // const interval = setInterval(() => {
      //   fetchData()
      // }, ONE_MINUTE)
      // return () => clearInterval(interval)
    }
  }, [eventHasLoaded])

  if (boats !== null && fleetViewState !== null) {
    return (
      <>
        <Map boats={boats} fleetViewState={fleetViewState} />
        <TopBar settingButtonVisible />
        <Clock
          time={lastRefresh}
          refreshData={fetchData}
          refreshIsPending={refreshBoatsDataIsPending}
        />
        <OptionDrawer />
      </>
    )
  } else if (error !== null) {
    return (
      <>
        <ModalMapBackground />
        <Modal
          isVisible={error !== null}
          modal={{
            title: null,
            body: `${error}` ?? 'Erreur inconnue.',
          }}
        />
        <TopBar settingButtonVisible={false} />
      </>
    )
  } else {
    return <Loading />
  }
}

export default DataFecther
