import {
  AttributionControl,
  _MapContext as MapContext,
  MapContextProps,
  NavigationControl,
  StaticMap,
} from 'react-map-gl'
import {
  BOAT_NAME_TEXT_COLOR,
  TRAIL_COLORS,
  hexToRgbArray,
} from '@utils/colors'
import { BitmapLayer, IconLayer, TextLayer } from '@deck.gl/layers'
import ControlsContext, {
  LocationAge,
  LocationAgeAsDuration,
} from '@contexts/controls_context'
import { Paper, Tooltip } from '@material-ui/core'
import { PickInfo, Position } from 'deck.gl'
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import ViewState, {
  ViewStateObject,
  viewStatesAreEquals,
} from '@view_models/view_state'
import {
  compassSvgIcon,
  zoomInSvgIcon,
  zoomOutSvgIcon,
} from '@assets/navigation_svg_icons'
import { eventSlug, isInEventMode } from '@utils/event'

import BOAT_ICON from '@assets/boats.png'
import { DateTime } from 'luxon'
import DeckGL from '@deck.gl/react'
import ErrorContext from '@contexts/error_context'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import Mapbox from 'react-map-gl/src/mapbox/mapbox'
import { MapboxLayer } from '@deck.gl/mapbox'
import Popup from '@components/popup'
import { Position2D } from '@deck.gl/core'
import SettingsIcon from '@material-ui/icons/Settings'
import ShareIcon from '@material-ui/icons/Share'
import ShareModal from './share_modal'
import { TOP_BAR_OFFSET } from '@utils/consts'
import ThemeContext from '@contexts/theme_context'
import { TileLayer } from '@deck.gl/geo-layers'
import { UndefinedError } from '@errors/default_error'
import { useCookies } from 'react-cookie'

export interface IconDataObject {
  id: number
  coordinate: [number, number]
  cog: number | undefined
  link: string | undefined
  tooltip_title: string | undefined
  tooltip_subtitle: string | undefined
  tooltip_desc1: string | undefined
  tooltip_desc2: string | undefined
  timestamp_utc: string
  type: string
}

interface DeckGlViewStateProps {
  viewState: any
  interactionState: {
    inTransition?: boolean
    isDragging?: boolean
    isPanning?: boolean
    isRotating?: boolean
    isZooming?: boolean
  }
  oldViewState: any
}

const INITIAL_VIEW_STATE = {
  bearing: 0,
  latitude: 47,
  longitude: -3,
  pitch: 0,
  zoom: 6,
}

const ICON_MAPPING = {
  boat: { x: 0, y: 0, width: 250, height: 250, mask: true },
  boatNoHeader: {
    x: 250,
    y: 0,
    width: 250,
    height: 250,
    mask: true,
  },
}

const COOKIE_NAME = 'viewState'

const ICON_LAYER_ID = 'icon-layer'
const TILE_LAYER_ID = 'tile-layer'
const TEXT_LAYER_ID = 'text-layer'
const GET_BOAT_ANIMATION_DURATION = 500

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navigation: {
      position: 'absolute',
      top: 10,
      right: 10,
      boxShadow: theme.shadows[6],
      '& > div': {
        backgroundColor: theme.palette.background.paper,
      },
      '& > div > button > span': {
        backgroundColor: theme.palette.text.primary,
        backgroundImage: 'none !important',
      },
      '& > div > button:nth-child(1)': {
        maskImage: zoomInSvgIcon,
      },
      '& > div > button:nth-child(2)': {
        maskImage: zoomOutSvgIcon,
      },
      '& > div > button:nth-child(3)': {
        maskImage: compassSvgIcon,
      },
    },
    attribution: {
      right: 0,
      bottom: 0,
      fontFamily: 'Roboto',
      fontSize: 'small',
    },
    actionButtonsContainer: {
      color: theme.palette.text.primary,
      position: 'absolute',
      width: 29,
      top: 107,
      right: 10,
      boxShadow: theme.shadows[6],
    },
    actionButtonsContainerShadow: {
      boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
      '& > *:not(:first-child)': {
        borderTop: '1px solid #fff',
      },
    },
    actionButton: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 29,
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  }),
)

interface Props {
  boats: IconDataObject[]
  fleetViewState: ViewStateObject
}

const Map: FunctionComponent<Props> = (props) => {
  const { boats, fleetViewState } = props

  const theme = useContext(ThemeContext)
  const controls = useContext(ControlsContext)
  const errorContext = useContext(ErrorContext)

  const filterBoatOnAge = (): IconDataObject[] => {
    if (controls.positionsMaxAge === LocationAge.All) {
      return boats
    }

    const maxAgeAsDateTime = DateTime.now().minus(
      LocationAgeAsDuration[controls.positionsMaxAge],
    )

    return boats.filter(
      (boat) => DateTime.fromISO(boat.timestamp_utc) > maxAgeAsDateTime,
    )
  }

  const agefilteredBoats = useMemo(filterBoatOnAge, [
    boats,
    controls.positionsMaxAge,
  ])

  const eventAwareCookie = COOKIE_NAME + (eventSlug ?? '')

  const [cookies, setCookie] = useCookies([eventAwareCookie])

  const [clickTooltipDataId, setClickTooltipDataId] = useState<number | null>(
    null,
  )
  const [hoverTooltipDataId, setHoverTooltipDataId] = useState<number | null>(
    null,
  )
  const [viewState, setViewState] =
    useState<ViewStateObject>(INITIAL_VIEW_STATE)
  const clickTooltipData = useMemo(
    () => agefilteredBoats.find((boat) => boat.id === clickTooltipDataId),
    [clickTooltipDataId, agefilteredBoats],
  )
  const hoverTooltipData = useMemo(
    () => agefilteredBoats.find((boat) => boat.id === hoverTooltipDataId),
    [hoverTooltipDataId, agefilteredBoats],
  )

  const shouldDisplaySettingsIcon = !isInEventMode

  const [glContext, setGLContext] = useState<WebGLRenderingContext>()
  const deckRef = useRef<DeckGL<MapContextProps>>(null)
  const mapRef = useRef<Mapbox>(null)

  const [shareModalIsDisplayed, setShareModalIsDisplayed] =
    useState<boolean>(false)

  useEffect(() => {
    const onNoCookiedViewState = () => {
      zoomToRaceArea()
    }

    const cookiedViewState = cookies[eventAwareCookie]
    if (cookiedViewState != undefined) {
      try {
        const newViewState = new ViewState(
          cookiedViewState.longitude,
          cookiedViewState.latitude,
          cookiedViewState.zoom,
        ).toDeckGlViewState()
        setViewState(newViewState)
      } catch (error) {
        if (error instanceof UndefinedError) {
          onNoCookiedViewState()
        } else {
          throw error
        }
      }
    } else {
      onNoCookiedViewState()
    }
  }, [])

  const onMapLoad = useCallback(() => {
    const map = mapRef.current!.getMap()
    const deck = deckRef.current!.deck

    map.addLayer(new MapboxLayer({ id: ICON_LAYER_ID, deck }))
    map.addLayer(new MapboxLayer({ id: TILE_LAYER_ID, deck }))
    map.addLayer(new MapboxLayer({ id: TEXT_LAYER_ID, deck }))

    map.on('style.load', onMapLoad)
  }, [])

  const setHoverInfo = (info: PickInfo<IconDataObject>) => {
    if (info.object) {
      setHoverTooltipDataId(info.object.id)
    } else {
      setHoverTooltipDataId(null)
    }
  }

  const shouldDisplayCenterButton = useMemo(
    () => !viewStatesAreEquals(viewState, fleetViewState),
    [viewState],
  )

  const zoomToRaceArea = (): void => {
    setViewState(fleetViewState)
  }

  const _onViewStateChange = (args: DeckGlViewStateProps) => {
    try {
      const newViewState = new ViewState(
        args.viewState.longitude,
        args.viewState.latitude,
        args.viewState.zoom,
      ).toDeckGlViewState()
      setViewState(newViewState)
      setCookie(eventAwareCookie, newViewState)
    } catch (error) {
      if (!(error instanceof UndefinedError)) {
        throw error
      }
    }
  }

  const layers = [
    new TileLayer({
      id: TILE_LAYER_ID,
      data: 'https://t1.openseamap.org/seamark/{z}/{x}/{y}.png',
      visible: controls.seaMarksAreVisible,
      minZoom: 0,
      maxZoom: 19,
      tileSize: 256,
      renderSubLayers: (tileProps: any) => {
        const {
          bbox: { west, south, east, north },
        } = tileProps.tile
        return new BitmapLayer(tileProps, {
          data: null,
          image: tileProps.data,
          bounds: [west, south, east, north],
        })
      },
    }),
    new TextLayer<IconDataObject>({
      id: TEXT_LAYER_ID,
      data: agefilteredBoats,
      getPosition: (d: IconDataObject): Position2D => d.coordinate,
      getText: (d: IconDataObject): string => d.tooltip_title || '',
      getSize: (d) => (viewState.zoom > 10 ? 13 : 0),
      getColor: BOAT_NAME_TEXT_COLOR,
      getAngle: 0,
      fontFamily: 'Roboto Mono, Monaco, monospace',
      fontWeight: 400,
      lineHeight: 1.75,
      getTextAnchor: 'start',
      getPixelOffset: [10, 20],
      getAlignmentBaseline: 'center',
      visible: controls.boatTagsAreVisible,
      updateTriggers: {
        getSize: [viewState.zoom],
      },
      transitions: {
        getPosition: {
          type: 'interpolation',
          duration: GET_BOAT_ANIMATION_DURATION,
        },
      },
    }),
    new IconLayer<IconDataObject>({
      id: ICON_LAYER_ID,
      data: agefilteredBoats,
      pickable: true,
      iconAtlas: BOAT_ICON,
      iconMapping: ICON_MAPPING,
      alphaCutoff: 0,
      getIcon: (d: IconDataObject) =>
        d.cog != undefined ? 'boat' : 'boatNoHeader',

      sizeUnits: 'meters',
      sizeMinPixels: 30,

      getPosition: (d: IconDataObject): Position => d.coordinate,
      getSize: (d) => 12,
      getColor: (d: IconDataObject) =>
        hexToRgbArray(TRAIL_COLORS[d.id % TRAIL_COLORS.length]),
      getAngle: (d: IconDataObject) => (d.cog ? 360 - d.cog : 0),
      onHover: (info: PickInfo<IconDataObject>) => setHoverInfo(info),
      onClick: (info: PickInfo<IconDataObject>) => {
        setClickTooltipDataId(info.object.id)
      },
      transitions: {
        getPosition: {
          type: 'interpolation',
          duration: GET_BOAT_ANIMATION_DURATION,
        },
      },
    }),
  ]

  const classes = useStyles()
  return (
    <>
      <DeckGL
        pickingRadius={12}
        height={window.innerHeight - TOP_BAR_OFFSET}
        style={{ marginTop: TOP_BAR_OFFSET }}
        ref={deckRef}
        layers={layers}
        ContextProvider={MapContext.Provider}
        viewState={viewState}
        controller={true}
        onClick={(info) => info.layer === null && setClickTooltipDataId(null)}
        onWebGLInitialized={setGLContext}
        onViewStateChange={_onViewStateChange}
        glOptions={{
          stencil: true,
        }}
        onError={(error) =>
          errorContext.setError(`${error.name}
  ${error.message}
  ${error.stack}`)
        }
      >
        {glContext && (
          <StaticMap
            // @ts-ignore
            ref={mapRef}
            key="map"
            gl={glContext}
            onLoad={onMapLoad}
            mapboxApiAccessToken={process.env.MAPBOX_API_ACCESS_TOKEN}
            mapStyle={theme.mapStyle}
            width="100%"
            height="100%"
            attributionControl={false}
            preventStyleDiffing
          />
        )}
        <NavigationControl className={classes.navigation} />
        <AttributionControl compact={false} className={classes.attribution} />

        <Paper className={classes.actionButtonsContainer}>
          {shouldDisplayCenterButton && (
            <Tooltip title="Voir tous les bateaux" placement="left">
              <div className={classes.actionButton} onClick={zoomToRaceArea}>
                <FullscreenIcon />
              </div>
            </Tooltip>
          )}
          <Tooltip title="Partager" placement="left">
            <div
              className={classes.actionButton}
              onClick={() => setShareModalIsDisplayed(true)}
            >
              <ShareIcon fontSize="small" />
            </div>
          </Tooltip>
          {shouldDisplaySettingsIcon && (
            <Tooltip title="Réglages" placement="left">
              <div
                className={classes.actionButton}
                onClick={controls.openDrawer}
              >
                <SettingsIcon fontSize="small" />
              </div>
            </Tooltip>
          )}
        </Paper>

        <Popup
          iconData={clickTooltipData!}
          isVisible={clickTooltipData != null}
          onClose={() => setClickTooltipDataId(null)}
        />

        <Popup
          iconData={hoverTooltipData!}
          isVisible={hoverTooltipData != null}
          onClose={null}
        />
      </DeckGL>
      <ShareModal
        isVisible={shareModalIsDisplayed}
        setIsVisible={setShareModalIsDisplayed}
      />
    </>
  )
}

export default Map
