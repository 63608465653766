import ControlsContext, { LocationAge } from '@contexts/controls_context'
import { DYNAMIC_DRAWER_WIDTH, TOP_BAR_HEIGHT } from '@utils/consts'
import { MenuItem, Paper, Select, Switch, Typography } from '@material-ui/core'
import React, { FunctionComponent, useContext } from 'react'
import {
  Theme,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles'

import Brightness3Icon from '@material-ui/icons/Brightness3'
import { COLORS } from '@utils/colors'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import LayersIcon from '@material-ui/icons/Layers'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import ThemeContext from '@contexts/theme_context'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import clsx from 'clsx'
import { isInEventMode } from '@utils/event'
import { version } from '~/package.json'

const SCSwitch = withStyles({
  switchBase: {
    color: COLORS.primary,
    '&$checked': {
      color: COLORS.primary,
    },
    '&$checked + $track': {
      backgroundColor: COLORS.primary,
    },
  },
  checked: {},
  track: {},
})(Switch)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      whiteSpace: 'nowrap',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `${DYNAMIC_DRAWER_WIDTH}px`,
      borderLeft: `${theme.palette.divider} solid 2px`,
    },
    header: {
      width: '100%',
      height: TOP_BAR_HEIGHT,
      backgroundColor: theme.palette.background.default,
      boxShadow: theme.shadows[3],
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'relative',
      marginBottom: 5,
    },
    sliders: {
      color: COLORS.primary,
    },
    title: {
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      margin: 0,
      left: 5,
    },
    optionItem: {
      margin: 5,
      marginBottom: 0,
      padding: 5,
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icons: {
      marginRight: 10,
      color: theme.palette.text.hint,
    },
    settingName: {
      flex: 1,
    },
    widthIcon: {
      transform: 'rotate(90deg)',
    },
    version: {
      textAlign: 'center',
      marginTop: 'auto',
      verticalAlign: 'bottom',
    },
  }),
)

const OptionDrawer: FunctionComponent = () => {
  const classes = useStyles()

  const theme = useContext(ThemeContext)
  const controls = useContext(ControlsContext)

  const showLocationAgeSelector = !isInEventMode

  return (
    <Drawer
      elevation={0}
      BackdropProps={{ invisible: true }}
      anchor="right"
      open={controls.drawerIsOpen}
      className={clsx(classes.drawer)}
      classes={{
        paper: clsx(classes.drawer),
      }}
      onClose={controls.closeDrawer}
    >
      <div className={classes.header}>
        <IconButton
          className={classes.closeButton}
          onClick={controls.closeDrawer}
        >
          <ChevronRightIcon />
        </IconButton>
        <Typography className={classes.title} variant="h6">
          Options
        </Typography>
      </div>
      {showLocationAgeSelector && (
        <Paper className={classes.optionItem}>
          <TimelapseIcon fontSize="medium" className={classes.icons} />
          <Typography className={classes.settingName}>
            Âge des positions
          </Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={controls.positionsMaxAge}
            onChange={(event) =>
              controls.setPositionsMaxAge(event.target.value as LocationAge)
            }
          >
            {(Object.keys(LocationAge) as Array<keyof typeof LocationAge>).map(
              (locationAge) => (
                <MenuItem
                  key={LocationAge[locationAge]}
                  value={LocationAge[locationAge]}
                >
                  {LocationAge[locationAge]}
                </MenuItem>
              ),
            )}
          </Select>
        </Paper>
      )}
      <Paper className={classes.optionItem}>
        <LayersIcon fontSize="medium" className={classes.icons} />
        <Typography className={classes.settingName}>
          Phares et Balises
        </Typography>
        <SCSwitch
          checked={controls.seaMarksAreVisible}
          onChange={controls.toggleSeaMarks}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Paper>
      <Paper className={classes.optionItem}>
        <LocalOfferIcon fontSize="medium" className={classes.icons} />
        <Typography className={classes.settingName}>Nom Bateaux</Typography>
        <SCSwitch
          checked={controls.boatTagsAreVisible}
          onChange={controls.toggleBoatTags}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Paper>

      <Paper className={classes.optionItem}>
        <Brightness3Icon fontSize="medium" className={classes.icons} />
        <Typography className={classes.settingName}>Mode Sombre</Typography>
        <SCSwitch
          checked={theme.isInDarkMode}
          onChange={theme.toggleTheme}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Paper>
      <Typography
        className={classes.version}
        variant="caption"
        color="textSecondary"
      >{`v${version}`}</Typography>
    </Drawer>
  )
}

export default OptionDrawer
