import axios from 'axios'
import { stringifyError } from '@utils/axios'

const POS_MAPS_EXPLORE_EVENT_URL = 'rpc/pos_maps_explore_event'

const getEventBoats = (eventSlug: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .post(POS_MAPS_EXPLORE_EVENT_URL, { e_slug: eventSlug })
      .then((request) => {
        const httpIsOk = request.status < 400
        if (httpIsOk) {
          resolve(request.data)
        } else {
          reject(`Impossible d'accèder à l'API.
  ${request.status} : ${request.statusText}`)
        }
      })
      .catch((error) => reject(stringifyError(error)))
  })
}

export default getEventBoats
