import {
  CircularProgress,
  MuiThemeProvider,
  Paper,
  Tooltip,
  Typography,
  createTheme,
} from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import { COLORS } from '~/src/service/utils/colors'
import { DateTime } from 'luxon'
import RefreshIcon from '@material-ui/icons/Refresh'
import { TOP_BAR_OFFSET } from '@utils/consts'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: 'fit-content',
      position: 'absolute',
      left: '50%',
      transform: `translate(-50%, ${TOP_BAR_OFFSET + 10}px)`,
      display: 'flex',
      flexDirection: 'row',
      boxShadow: theme.shadows[6],
      padding: 5,
      justifyContent: 'center',
      alignItems: 'center',
    },
    actionButton: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // height: 29,
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    text: {
      paddingRight: 5,
    },
  }),
)

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
  },
})

interface Props {
  time: DateTime
  refreshData: () => void
  refreshIsPending: boolean
}

const Clock: FunctionComponent<Props> = ({
  time,
  refreshData,
  refreshIsPending,
}) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper} elevation={3}>
      <Tooltip title="Date de la position la plus récente" placement="bottom">
        <Typography className={classes.text} variant="subtitle2">
          {time.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}
        </Typography>
      </Tooltip>
      <Tooltip title="Mettre à jour la position des bateaux" placement="bottom">
        {refreshIsPending ? (
          <MuiThemeProvider theme={theme}>
            <CircularProgress size={20} color="primary" />
          </MuiThemeProvider>
        ) : (
          <div className={classes.actionButton} onClick={refreshData}>
            <RefreshIcon fontSize="small" />
          </div>
        )}
      </Tooltip>
    </Paper>
  )
}

export default Clock
