import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
} from '@material-ui/core'
import React, { FunctionComponent, ReactNode, useMemo, useState } from 'react'

const DARK_MAP = 'mapbox://styles/sailcloud-ben/cks4k2ale4ral17qwk1eb793i'
const LIGHT_MAP = 'mapbox://styles/sailcloud-ben/cl4r8b2u7002p14muri7nzbae'

export enum MapStyle {
  Dark,
  Light,
}

const ThemeContext = React.createContext<IThemeProvider>({} as IThemeProvider)

interface ThemeProviderProps {
  children: ReactNode
}

export interface IThemeProvider {
  isInDarkMode: boolean
  mapStyle: any
  toggleTheme: () => void
  setIsInDarkMode: (isInDarkMode: boolean) => void
}

export const ThemeProvider: FunctionComponent<ThemeProviderProps> = (
  props: ThemeProviderProps,
) => {
  const [isInDarkMode, setIsInDarkMode] = useState(false)

  const toggleTheme = () => {
    setIsInDarkMode(!isInDarkMode)
  }

  const uiTheme = useMemo(
    () => (isInDarkMode ? 'dark' : 'light'),
    [isInDarkMode],
  )
  const mapStyle = useMemo(
    () => (isInDarkMode ? DARK_MAP : LIGHT_MAP),
    [isInDarkMode],
  )

  return (
    <ThemeContext.Provider
      value={{
        isInDarkMode: isInDarkMode,
        mapStyle: mapStyle,
        toggleTheme: toggleTheme,
        setIsInDarkMode: setIsInDarkMode,
      }}
    >
      <MUIThemeProvider
        theme={createTheme({
          palette: {
            type: uiTheme,
          },
        })}
      >
        {props.children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeContext
