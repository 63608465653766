import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import React, { FunctionComponent, useContext, useState } from 'react'

import { COLORS } from '@utils/colors'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import ThemeContext from '@contexts/theme_context'

interface DialogTitleProps {
  onClose: () => void
}

const styles = (theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle: FunctionComponent<DialogTitleProps> = withStyles(styles)(
  (props) => {
    const { children, classes, onClose, ...other } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  },
)

interface Props {
  isVisible: boolean
  setIsVisible: (shouldBeVisible: boolean) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    button: { marginTop: 20, float: 'right' },
  }),
)

const ShareModal: FunctionComponent<Props> = ({ isVisible, setIsVisible }) => {
  const { isInDarkMode } = useContext(ThemeContext)

  const [textHasBeenCopied, setTextHasBeenCopied] = useState<boolean>(false)

  const classes = useStyles()

  return (
    <Dialog
      open={isVisible}
      onClose={() => setIsVisible(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DialogTitle onClose={() => setIsVisible(false)}>Partager</DialogTitle>
      <DialogContent>
        <a
          style={
            isInDarkMode
              ? {
                  color: COLORS.secondary,
                  textDecorationColor: COLORS.secondary,
                }
              : {}
          }
          href={window.location.href}
        >
          <Typography>{window.location.href}</Typography>
        </a>
        <Button
          variant={textHasBeenCopied ? 'outlined' : 'contained'}
          // color="secondary"
          startIcon={textHasBeenCopied ? <DoneIcon /> : <FileCopyIcon />}
          className={classes.button}
          // @ts-ignore
          onClick={() => {
            navigator.clipboard
              .writeText(window.location.href)
              .then(() => setTextHasBeenCopied(true))
          }}
        >
          {textHasBeenCopied ? 'Lien copié !' : 'Copier lien'}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default ShareModal
