import EventInfo from '@view_models/event_info'
import { isInEventMode } from './event'

export const DRAWER_WIDTH = 300
export const DYNAMIC_DRAWER_WIDTH =
  window.innerWidth > DRAWER_WIDTH ? DRAWER_WIDTH : window.innerWidth
export const TOP_BAR_HEIGHT = 48
export const TOP_BAR_OFFSET = isInEventMode ? TOP_BAR_HEIGHT : 0
export const DEFAULT_TOP_BAR_URL =
  'https://buddy.sail.cloud/bbapp/screen/groups'
export const DEFAULT_TOP_BAR_NAME = 'Explore'
export const DEFAULT_EVENT_INFO: EventInfo = {
  name: DEFAULT_TOP_BAR_NAME,
  link: DEFAULT_TOP_BAR_URL,
}
