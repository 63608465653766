import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { eventSlug, isInEventMode } from '@utils/event'

import { DEFAULT_EVENT_INFO } from '@utils/consts'
import { Duration } from 'luxon'
import EventInfo from '@view_models/event_info'
import ThemeContext from './theme_context'
import { useCookies } from 'react-cookie'

const ControlsContext = React.createContext<IControlsProvider>(
  {} as IControlsProvider,
)

interface ControlsProviderProps {
  children: ReactNode
}

interface ControlsCookie {
  drawerIsOpen: boolean
  seaMarksAreVisible: boolean
  boatTagsAreVisible: boolean
  positionsMaxAge: LocationAge
  isInDarkMode: boolean
}

export interface IControlsProvider {
  drawerIsOpen: boolean
  seaMarksAreVisible: boolean
  boatTagsAreVisible: boolean
  positionsMaxAge: LocationAge
  eventInfo: EventInfo
  openDrawer: () => void
  closeDrawer: () => void
  toggleDrawer: () => void
  toggleSeaMarks: () => void
  toggleBoatTags: () => void
  setEventInfo: (eventInfo: EventInfo) => void
  setPositionsMaxAge: (locationMaxAge: LocationAge) => void
}

export enum LocationAge {
  Hour = 'heure',
  Day = 'jour',
  Week = 'semaine',
  Month = 'mois',
  All = 'tout',
}

export const LocationAgeAsDuration = {
  [LocationAge.Hour]: Duration.fromObject({ hours: 1 }),
  [LocationAge.Day]: Duration.fromObject({ days: 1 }),
  [LocationAge.Week]: Duration.fromObject({ weeks: 1 }),
  [LocationAge.Month]: Duration.fromObject({ months: 1 }),
  [LocationAge.All]: Duration.fromObject({}),
}

const COOKIE_NAME = 'controls'

export const ControlsProvider: FunctionComponent<ControlsProviderProps> = (
  props: ControlsProviderProps,
) => {
  const { isInDarkMode, setIsInDarkMode } = useContext(ThemeContext)

  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false)
  const [seaMarksAreVisible, setSeaMarksAreVisible] = useState<boolean>(true)
  const [boatTagsAreVisible, setBoatTagsAreVisible] = useState<boolean>(true)
  const [eventInfo, setEventInfo] = useState<EventInfo>(DEFAULT_EVENT_INFO)
  const [positionsMaxAge, setPositionsMaxAge] = useState<LocationAge>(
    isInEventMode ? LocationAge.All : LocationAge.Day,
  )

  const eventAwareCookie = COOKIE_NAME + (eventSlug ?? '')

  const [cookies, setCookie] = useCookies([eventAwareCookie])

  const controlsCookieObjectIsValid = (controlsCookie: any) => {
    return (
      controlsCookie != undefined &&
      typeof controlsCookie.drawerIsOpen === 'boolean' &&
      typeof controlsCookie.seaMarksAreVisible === 'boolean' &&
      typeof controlsCookie.boatTagsAreVisible === 'boolean' &&
      Object.values(LocationAge).includes(controlsCookie.positionsMaxAge) &&
      typeof controlsCookie.isInDarkMode === 'boolean'
    )
  }

  useEffect(() => {
    const cookiedControls = cookies[eventAwareCookie] as ControlsCookie
    const cookiedControlsAreValid = controlsCookieObjectIsValid(cookiedControls)

    if (cookiedControlsAreValid) {
      setDrawerIsOpen(cookiedControls.drawerIsOpen)
      setSeaMarksAreVisible(cookiedControls.seaMarksAreVisible)
      setBoatTagsAreVisible(cookiedControls.boatTagsAreVisible)
      setPositionsMaxAge(cookiedControls.positionsMaxAge)
      setIsInDarkMode(cookiedControls.isInDarkMode)
    }
  }, [])

  const buildControlsCookie = (): ControlsCookie => {
    return {
      drawerIsOpen: drawerIsOpen,
      seaMarksAreVisible: seaMarksAreVisible,
      boatTagsAreVisible: boatTagsAreVisible,
      positionsMaxAge: positionsMaxAge,
      isInDarkMode: isInDarkMode,
    }
  }

  useEffect(() => {
    setCookie(eventAwareCookie, buildControlsCookie())
  }, [
    drawerIsOpen,
    seaMarksAreVisible,
    boatTagsAreVisible,
    eventInfo,
    positionsMaxAge,
    isInDarkMode,
  ])

  const openDrawer = (): void => {
    setDrawerIsOpen(true)
  }

  const closeDrawer = (): void => {
    setDrawerIsOpen(false)
  }

  const toggleDrawer = (): void => {
    setDrawerIsOpen(!drawerIsOpen)
  }

  const toggleSeaMarks = (): void => {
    setSeaMarksAreVisible(!seaMarksAreVisible)
  }

  const toggleBoatTags = (): void => {
    setBoatTagsAreVisible(!boatTagsAreVisible)
  }

  return (
    <ControlsContext.Provider
      value={{
        drawerIsOpen: drawerIsOpen,
        seaMarksAreVisible: seaMarksAreVisible,
        boatTagsAreVisible: boatTagsAreVisible,
        eventInfo: eventInfo,
        positionsMaxAge: positionsMaxAge,
        openDrawer: openDrawer,
        closeDrawer: closeDrawer,
        toggleDrawer: toggleDrawer,
        toggleSeaMarks: toggleSeaMarks,
        toggleBoatTags: toggleBoatTags,
        setEventInfo: setEventInfo,
        setPositionsMaxAge: setPositionsMaxAge,
      }}
    >
      {props.children}
    </ControlsContext.Provider>
  )
}

export default ControlsContext
