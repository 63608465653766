import axios from 'axios'
import { stringifyError } from '@utils/axios'

const POS_MAPS_EXPLORE_URL = 'pos_maps_explore'

const getBoats = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .get(POS_MAPS_EXPLORE_URL)
      .then((request) => {
        const httpIsOk = request.status < 400
        if (httpIsOk) {
          resolve(request.data)
        } else {
          reject(`Impossible d'accèder à l'API.
  ${request.status} : ${request.statusText}`)
        }
      })
      .catch((error) => reject(stringifyError(error)))
  })
}

export default getBoats
