import { Card, CardContent, Typography } from '@material-ui/core'
import React, { FunctionComponent } from 'react'

import { COLORS } from '@utils/colors'

export interface ModalType {
  title: string | null
  body: string | null
}

interface Props {
  modal: ModalType
  isVisible: boolean
}

const Modal: FunctionComponent<Props> = (props: Props) => {
  return (
    <div
      style={{
        height: window.innerHeight,
        width: window.innerWidth,
        backgroundColor: COLORS.backdropTint,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <Card>
        <CardContent>
          {props.modal.title && (
            <Typography gutterBottom variant="h5" component="h2">
              {props.modal.title}
            </Typography>
          )}
          {props.modal.body && (
            <Typography variant="body2" color="textSecondary" component="p">
              {props.modal.body}
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default Modal
