import React, { FunctionComponent, useContext } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import ControlsContext from '@contexts/controls_context'
import IconButton from '@material-ui/core/IconButton'
import Logo from '@assets/favicon.svg'
import SettingsIcon from '@material-ui/icons/Settings'
import { TOP_BAR_HEIGHT } from '@utils/consts'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { isInEventMode } from '@utils/event'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      backgroundColor: theme.palette.background.default,
      paddingRight: 0,
      boxShadow: theme.shadows[6],
    },
    title: {
      flexGrow: 1,
      color: theme.palette.text.primary,
      display: 'flex',
      flexDirection: 'column',
      minWidth: 0,
      textDecoration: 'none',
      marginLeft: 20,
    },
    leftIcon: {
      width: TOP_BAR_HEIGHT - 12,
      height: TOP_BAR_HEIGHT - 12,
      margin: 6,
    },
    rightIcon: {
      color: theme.palette.text.primary,
    },
    fabLogo: {
      width: TOP_BAR_HEIGHT,
      height: TOP_BAR_HEIGHT,
      position: 'absolute',
      top: 10,
      left: 10,
      borderRadius: TOP_BAR_HEIGHT / 2,
      boxShadow: theme.shadows[6],
    },
    fabLogoImg: {
      width: TOP_BAR_HEIGHT,
      height: TOP_BAR_HEIGHT,
      cursor: 'pointer',
    },
  }),
)

interface Props {
  settingButtonVisible: boolean
}

const TopBar: FunctionComponent<Props> = (props) => {
  const { settingButtonVisible } = props

  const controls = useContext(ControlsContext)

  const classes = useStyles()

  const shouldDisplayTopBar = isInEventMode

  if (shouldDisplayTopBar) {
    return (
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar} variant="dense">
          <img className={classes.leftIcon} src={Logo} alt="SAIL.cloud Logo" />
          <div className={classes.title}>
            <Typography variant="h6">{controls.eventInfo.name}</Typography>
          </div>
          {(settingButtonVisible ?? true) && (
            <IconButton
              className={classes.rightIcon}
              aria-label="menu"
              onClick={controls.openDrawer}
            >
              <SettingsIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    )
  } else {
    return (
      <div className={classes.fabLogo}>
        <a href={process.env.BUDDY_ABOUT_PAGE_URL} target="_blank">
          <img
            className={classes.fabLogoImg}
            src={Logo}
            alt="SAIL.cloud Logo"
          />
        </a>
      </div>
    )
  }
}

export default TopBar
