import EventInfo from '@view_models/event_info'
import EventInfoDTO from '@dto/event_info_dto'
import axios from 'axios'
import { stringifyError } from '@utils/axios'

const getEventInfos = (eventSlug: string): Promise<EventInfo> => {
  return new Promise((resolve, reject) => {
    axios
      .get<[EventInfoDTO]>(`events?slug=eq.${eventSlug}&select=name,link`)
      .then((request) => {
        const httpIsError = request.status >= 400
        if (httpIsError) {
          return reject(`Impossible d'accèder à l'API.
${request.status} : ${request.statusText}`)
        }

        const eventExists = request.data && request.data.length > 0
        if (eventExists) {
          resolve(request.data[0])
        } else {
          reject(`Évènement "${eventSlug}" inconnu.`)
        }
      })
      .catch((error) => reject(stringifyError(error)))
  })
}

export default getEventInfos
