import { RGBAColor } from '@deck.gl/core/utils/color'

export function hexToRgbArray(hexColor: string): RGBAColor {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor)
  if (result) {
    return [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
    ]
  } else {
    throw new SyntaxError(
      `Expected color in hexadecimal format (e.g. #12AB12), but got ${hexColor}.`,
    )
  }
}

export const TRAIL_COLORS = [
  '#DDB27C',
  '#FF991F',
  '#F15C17',
  '#223F9A',
  '#DA70BF',
  '#4DC19C',
  '#17B8BE',
  '#FFCB99',
  '#F89570',
  '#829AE3',
  '#E79FD5',
  '#F6D18A',
  '#1E96BE',
  '#89DAC1',
]

// '#12939A',
// '#88572C',
// '#125C77',
// '#776E57',
// '#B7885E',
// '#B3AD9E',

export const BOAT_NAME_TEXT_COLOR = [
  255 / 2,
  255 / 2,
  255 / 2,
  200,
] as RGBAColor

export const COLORS = {
  primary: '#3a9ebf',
  secondary: '#cce310',
  primaryDark: '#1d2350',
  backdropTint: '#000000AA',
}
