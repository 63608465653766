import { Link, Typography } from '@material-ui/core'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { DateTime } from 'luxon'
import { IconDataObject } from '@components/map'
import { Popup as RMPopup } from 'react-map-gl'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popupContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    linkContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
)

interface Props {
  iconData: IconDataObject
  onClose: null | (() => void)
  isVisible: boolean
}

interface PopupDesc2Props {
  timestamp_utc: string
  type: string
}

const formatDiffValue = (value: number | undefined, unit: string): string => {
  return value != undefined && value >= 1 ? `${Math.floor(value)}${unit}` : ''
}

const computeTimestampDiffWithNow = (pastTimestamp: string): string => {
  const timestamp = DateTime.fromISO(pastTimestamp)
  const now = DateTime.now()

  const diff = now
    .diff(timestamp, ['days', 'hours', 'minutes', 'seconds'])
    .toObject()

  const { days, hours, minutes, seconds } = diff

  return `${formatDiffValue(days, 'j')} ${formatDiffValue(
    hours,
    'h',
  )} ${formatDiffValue(minutes, 'm')} ${formatDiffValue(seconds, 's')}`
}

const PopupDesc2: FunctionComponent<PopupDesc2Props> = (props) => {
  const { timestamp_utc, type } = props

  const [timestampDiffNow, setTimestampDiffNow] = useState<string>(
    computeTimestampDiffWithNow(timestamp_utc),
  )

  const updateTimestampDiff = () => {
    const test = computeTimestampDiffWithNow(timestamp_utc)
    setTimestampDiffNow(test)
  }

  React.useEffect(() => {
    updateTimestampDiff()
    const interval = setInterval(() => {
      updateTimestampDiff()
    }, 1000)
    return () => clearInterval(interval)
  }, [timestamp_utc])

  return (
    <Typography variant="caption" noWrap>
      {`${type} - il y a ${timestampDiffNow}`}
    </Typography>
  )
}

export default function Popup(props: Props) {
  const { iconData, onClose, isVisible } = props

  if (!isVisible) {
    return <></>
  }

  const classes = useStyles()

  return (
    <RMPopup
      tipSize={5}
      anchor="top"
      latitude={iconData?.coordinate[1] - 0.00004}
      longitude={iconData?.coordinate[0]}
      offsetTop={8}
      closeOnClick={false}
      onClose={onClose!}
      closeButton={onClose !== null}
    >
      <div className={classes.popupContainer}>
        <div style={{ maxWidth: 200 }}>
          <Typography variant="button" component="h2" noWrap>
            {iconData.tooltip_title != undefined ? iconData.tooltip_title : '-'}
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {iconData.tooltip_subtitle != undefined
              ? iconData.tooltip_subtitle
              : '-'}
          </Typography>
          <Typography variant="body2" noWrap>
            {iconData.tooltip_desc1 != undefined ? iconData.tooltip_desc1 : '-'}
          </Typography>
          <PopupDesc2 {...iconData} />
        </div>
        {iconData.link && (
          <div className={classes.linkContainer}>
            <Link href={iconData.link}>
              <ChevronRightIcon fontSize="large" />
            </Link>
          </div>
        )}
      </div>
    </RMPopup>
  )
}
